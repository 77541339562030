
import Vue, { PropType } from "vue";

import MyPreview from "@/components/MyPreview.vue";

import http from "@/http";

import { Owner, User, Snackbar, Family } from "@/interfaces";

export interface DataType {
  memo: string;
  family?: Family;
  families: Family[];
}

export default Vue.extend({
  components: {
    MyPreview
  },
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  data(): DataType {
    return {
      memo: "",
      family: undefined,
      families: []
    };
  },
  computed: {
    items(): Family[] {
      return this.$store.state.families;
    }
  },
  mounted() {
    this.memo = this.owner.memo;
    this.families = this.owner.families;
  },
  methods: {
    close(index: number) {
      this.families.splice(index, 1);
    },
    change() {
      if (!this.family) {
        return;
      }

      this.families.push(this.family);

      this.family = undefined;
    },
    async submit() {
      const url = `/owners/${this.owner.id}`;

      const families = this.families.map(family => {
        return family.id;
      });

      await http.put<Owner>(url, {
        memo: this.memo,
        families
      });

      this.$router.push(`/owner/${this.owner.id}`);
    }
  }
});
